<template>
  <div class="mobileDetail">
    <div class="bodyCenterLeft" v-if="detail">
      <div class="titleBiao" v-html="detail.caseTitle"></div>
      <div class="titleFu" id="">
        <span class="titileSubtitle">{{detail.court}}</span>
        <span class="titileSubtitle">{{detail.caseNo}}</span>
        <span class="titileSubtitle" v-if="detail.judgementDate != ''&&detail.judgementDate != '1970-01-01'">{{detail.judgementDate}}</span>
        <span class="titileSubtitle">{{detail.reason}}</span>
      </div>
      <div v-for="(item,index) in textListData" class="caipanBody" :key="index" :id="item.type">
        <div class="caipanyaodian">
          <div class="backlanse"></div>
          <div class="typeText">{{item.typeText}}</div>
          <div class="backImg">
            <img src="@/assets/img/juxing.png" alt="">
          </div>
        </div>
        <div class="yaodianMsg" v-for="(itemChild,indexChild) in item.child" :id="itemChild.id" :key="indexChild" v-html="itemChild.text"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Server from "@/api/server";

export default {
  name: "mobileDetail",
  data(){
    return{
      detail:null
    }
  },
  mounted() {
    this.linkToDetail()
  },
  methods:{
    async linkToDetail () {
      let id = this.$route.query.documentId
      this.visible = true
      //请求数据
      let data = {
        method: 'GET',
        url: 'themis-terminal-api/b/q/caseMaintenance/getInstrumentDetail/'+ id,
        loading: false
      };
      let dataSource = await Server.Axios(data);
      if(dataSource.data.returnCode == 500){
        this.$message.error('服务出错，请稍后重试');
        return
      }
      if (dataSource.data.returnCode == 200){
        this.detail = dataSource.data.returnData
        let newBodyText = this.detail.segments.filter(arrItem => arrItem.typeText != null && arrItem.typeText != "案号"  && arrItem.typeText != arrItem.text);
        // 循环组装数组对象
        let dataInfo = {};
        newBodyText.forEach((item, index) => {
          let { typeText } = item;
          // item['id'] = 'paragraph'+ index
          if (!dataInfo[typeText]) {
            dataInfo[typeText] = {
              typeText,
              child: [],
              type:'type'+index
            }
          }
          dataInfo[typeText].child.push(item);
        });
        //对象转数组
        let listData = Object.values(dataInfo);
        // 组装完成 数据 放到data里
        this.textListData = listData;
        window.console.log(listData)
      }
    }
  }
}
</script>

<style scoped lang="less">
.mobileDetail{
  padding: .3rem;
}
.titleBiao{
  font-size:22px;
  font-weight:400;
  color:rgba(51,51,51,1);
  margin-bottom: 15px;
}
.titleFu{
  color:rgba(136,136,136,1);
  font-size:14px;
}
.biaoqian{
  padding-left: 20px;
  height:42px;
  line-height: 42px;
  background:rgba(2,146,254,0.05);
  /*opacity:0.05;*/
  color: #6C7277;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  .biaoqianImg{
    margin-right: 5px;
  }
  img{
    width:16px;
    height:16px;
    transform:rotate(90deg);
    -ms-transform:rotate(90deg); 	/* IE 9 */
    -moz-transform:rotate(90deg); 	/* Firefox */
    -webkit-transform:rotate(90deg); /* Safari 和 Chrome */
    -o-transform:rotate(90deg);
  }
}
.caipanyaodian{
  display: flex;
  flex-direction: row;
  font-size:20px;
  font-weight:400;
  color:rgba(2,146,254,1);
  //background: url("./assets/juxing.png") no-repeat right;
}
.typeText{
  margin-right: 15px;
}
.backImg{
  flex:1;
  img{
    width: 100%;
    height: 12px;
  }
}
.caipanBody{
  margin-top: 10px;
}
.backlanse{
  margin-top: 7px;
  width:4px;
  height:18px;
  background:rgba(2,146,254,1);
  margin-right: 16px;
}
.yaodianMsg{
  font-size:14px;
  font-weight:400;
  color:rgba(102,102,102,1);
  //line-height:28px;
  //margin-top: 14px;
  text-indent:2em;
  font-size: 16px;
  line-height: 32px;
}

</style>